<template>
    <div>      
      <b-modal
          id="modal-form"
          ok-only
          centered
          size="md"
          content-class="shadow"
          hide-footer
        >
        <template #modal-title> <div style="font-size:24px; font-weight:600;">{{createMode ? 'Tambah' : 'Ubah'}} Jenis</div></template>
          <div >
            <b-form-row>
              <b-col>
                <b-row class="d-flex align-items-center">
                  <b-col sm="5" >
                    <p style="font-weight: 600; margin:0px;">Bidang Kegiatan</p>
                  </b-col>:<b-col sm="6">
                      <b-form-input v-model="dataSubmit" placeholder="Masukan Bidang Kegiatan"></b-form-input>
                  </b-col>
                </b-row>  
              </b-col>
            </b-form-row>
            <div class="text-center">
              <b-button
                variant="outline-primary"
                class="mt-16 text-center mr-5"
                @click="$bvModal.hide('modal-form')"
              >
                Batal
              </b-button>
              <b-button
                variant="primary"
                class="mt-16 text-center"
                @click="submitForm()"
              >
                {{createMode ? 'Tambah' : 'Ubah'}}
              </b-button>
            </div>
          </div>
      </b-modal>
      <b-modal
        id="formKonfirmasi"
        ok-only
        centered
        size="md"
        content-class="shadow"
        hide-header
        hide-footer
      >
        <div>
          <b-container>
            <p style="font-size:18px; margin-top:20px;">
              Apakah anda yakin ingin Menghapus Jenis Bidang Kegiatan?
            </p>
          </b-container>
          <div class="text-center">
            <b-button
              variant="outline-secondary"
              class="mt-16 text-center mr-5"
              @click="$bvModal.hide('formKonfirmasi')"
            >
              Tidak
            </b-button>
            <b-button
              variant="danger"
              class="mt-16 text-center"
              @click="deleteData()"
            >
              Yakin
            </b-button>
          </div>
        </div>
      </b-modal>
      <b-row class="mt-5">
          <b-col class="d-flex align-items-center justify-content-between">
            <span style="font-size:16px; font-weight:bold;">Jenis Bidang Kegiatan</span>
            <div>
                <b-button
                size="sm"
                variant="primary"
                @click="createJenis()"
              >
                <v-icon
                  style="color:white;"
                  small
                >mdi-plus-box</v-icon>
                Tambah Jenis
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div
            class="table-responsive mt-4"
            style="border-radius:15px;"
          >
            <table
              class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-bordered
          "
              style="font-size:14px !important;"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 20px" class="text-capitalize">No.</th>
                  <th style="min-width: 50px" class="text-capitalize">Jenis Bidang Kegiatan</th>
                  <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                </tr>
              </thead>
              <!-- <tbody v-if="list.length == 0">
                  <template>
                      <tr>
                          <td colspan="6"> -- Tidak ada permohonan -- </td>
                      </tr>
                  </template>
              </tbody> -->
              <tbody style="background: #FFFFFF;">
                <template v-for="(item, i) in list">
                  <tr v-bind:key="i">
                    <td >
                      <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      font-size-lg
                    ">{{ (i+1) + ((currentPage-1)*perPage)}}</span>
                    </td>
                    <td class="text-left">
                      <span  class="
                      text-dark-75
                      font-weight-bolder
                      text-left
                      font-size-lg
                    "> {{item.namaJenisOrmas}}
                      </span>
                    </td>
                    <td>
                      <section>
                      <b-button variant="danger" size="sm" style="padding:5px" class="mr-1" @click="confirmDelete(item.id)">
                            <v-icon style="color:white;padding:0px;" small>mdi-trash-can-outline</v-icon>
                        </b-button>
                        <b-button variant="primary" size="sm" style="padding:5px" class="mr-1" @click="editJenis(item)">
                            <v-icon style="color:white;padding:0px;" small>mdi-pencil</v-icon>
                        </b-button>
                      </section>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-row>
            <b-col class="d-flex justify-content-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </b-col>
          </b-row>
    </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  name: "Informasi",
  data() {
    return {
      list: [],
      lisList: false,
      listBidangKegiatan: [],
      dataSubmit: null,
      idnya: null,
      createMode: true,
      currentPage: 1,
      currentOffset: 0,
      totalRows: 0,
      perPage: 20,
      query: {},
    };
  },
  mounted() {
    this.fetchData()
  },
  watch:{
    currentPage(to){
      this.currentOffset = to - 1
      this.fetchData()
    },
    activeTab(/*to*/){
      
    }
  },
  methods: {
    createJenis(){
      this.createMode = true
      this.dataSubmit = ''
      this.$bvModal.show('modal-form')
    },
    editJenis(data){
      this.idnya = data.id
      this.createMode = false
      this.dataSubmit = data.namaJenisOrmas
      this.$bvModal.show('modal-form')
    },
    fetchData(){
      this.lisList = true
      this.listBidangKegiatan = []
      this.query = {
        offset: this.currentOffset * 20,
        limit: 20,
        skip: 0
      };
      this.itemCountGet(this.query)
      this.$store
        .dispatch("bidangKegiatanList",this.query)
        .then((res) => {
          this.list = res.data
          this.lisList = false
        })
    },
    paginateBidangKegiatan() {
      // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
      this.list = this.listBidangKegiatan.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    async itemCountGet() {
      // if(q && q.bk || q.ss || q.ck || q.jo || q.sk || q.no){
      this.$store
        .dispatch("bidangKegiatanListCount")
        .then((res) => {
          this.totalRows = res.data.count
      })
    },
    submitForm(){
      let data = {
        "namaJenisOrmas": this.dataSubmit
      }

      if(this.createMode ){
        this.$store
        .dispatch("submitBidangKegiatan", data)
        .then(() => {
          Swal.fire({
            title: "Data berhasil disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.$bvModal.hide('modal-form')
            this.dataSubmit = ''
            this.fetchData()
          })
        })
      }else {
        this.$store
        .dispatch("submitPatchBidangKegiatan", [data, this.idnya])
        .then(() => {
          Swal.fire({
            title: "Data berhasil diubah",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.$bvModal.hide('modal-form')
            this.dataSubmit = ''
            this.fetchData()
          })
        })
      }
    },
    deleteData(){
      this.$store
        .dispatch("deleteBidangKegiatan", this.idnya)
        .then(() => {
          Swal.fire({
            title: "Data berhasil dihapus",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then(() => {
            this.$bvModal.hide('formKonfirmasi')
            this.fetchData()
          })
        })
    },
    confirmDelete(id){
      this.idnya = id
      this.$bvModal.show('formKonfirmasi')
    }
  },
  computed: {
    
  }
};
</script>